<template>
	<div class="data-content">
		<div class="top-box">
			<div>
				<button v-if="perms.includes('home:repair:extendedWarrantyRecord:add')"
						class="add-btn"
						@click="handleAdd()">新增
				</button>
			</div>
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input"
						  size="small"
						  type="text"
						  v-model="searchKey"
						  placeholder="请输入关键字"></el-input>
				<img class="search-btn"
					 src="@/assets/images/sousuo.png"
					 alt=""
					 @click="getDataList">
				<el-button icon="el-icon-refresh-right"
						   class="refresh-btn"
						   @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			<el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				class="scroll-table"
				style="width: 100%;height: 100%;">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{ scope.$index + 1 }}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="machineNo"
					label="机号"
				></el-table-column>
				<el-table-column
					prop="repairAcceptDate"
					label="延保结束日期"
				>
					<template slot-scope="scope"> {{ formatTimestamp(scope.row.extendedDate) }}</template>
				</el-table-column>
				<el-table-column
					fixed="right"
					label="操作"
					width="180">
					<template slot-scope="scope">
						<button class="btn-blue"
								@click="handleView(scope.row)">查看
						</button>
						<button v-if="perms.includes('home:repair:extendedWarrantyRecord:update')"
								class="btn-blue"
								@click="handleEdit(scope.row)">编辑
						</button>
						<button v-if="perms.includes('home:repair:extendedWarrantyRecord:delete')"
								class="btn-red"
								@click="handleDelete(scope.row)">删除
						</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="650px">
				<span slot="title"
					  class="dialog-title">
					<span>{{ dialogTitle }}</span>
					<img src="@/assets/images/close.png"
						 alt=""
						 @click="visible = false">
				</span>
			<div class="pb0">
				<el-form :model="dataForm"
						 :rules="dataRule"
						 label-position="top"
						 ref="dataForm">
					<div class="inline-form-item">
						<el-form-item label="机号"
									  filterable
									  prop="machineNo">
							<el-input v-model="dataForm.machineNo"
									  placeholder="请输入"
									  maxlength="32"
									  :disabled="readonly"></el-input>
						</el-form-item>
						<el-form-item label="延保结束日期"
									  filterable
									  prop="extendedDate">
							<el-date-picker
								v-model="dataForm.extendedDate"
								type="date"
								value-format="yyyy-MM-dd"
								placeholder="请选择"
								class="full-width"
								:disabled="readonly"
							></el-date-picker>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<span slot="footer"
				  class="dialog-footer pt0">
				<el-button v-if="!readonly"
						   :loading="submitLoading"
						   class="submit-btn"
						   @click="formSubmit">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {
	repairTypeOptions,
	serviceTypeOptions,
	repairAuditStatusOptions,
} from "@/constant/options"
import {debounce} from "@/utils/debounce";

export default {
	components: {
		Pagination,
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			// 弹窗数据
			id: '',
			dialogTitle: '',
			visible: false,
			readonly: false,
			submitLoading: false,
			dataForm: {
				machineNo: '', // 机号
				extendedDate: '' // 延保结束日期
			},
			dataRule: {
				machineNo: [
					{required: true, message: '机号不能为空', trigger: "blur"}
				],
				extendedDate: [
					{required: true, message: '延保结束日期不能为空', trigger: "blur"}
				]
			},

			// 表格数据
			dataList: [],
			searchKey: '',
			pageNo: 1,
			pageSize: 10,
			total: 0,
			repairTypeOptions,
			serviceTypeOptions,
			repairAuditStatusOptions,
		}
	},
	created() {
		this.getDataList()
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row, rowIndex}) {
			if (rowIndex % 2 == 1) {
				return 'blue'
			} else {
				return 'yellow'
			}
		},
		formatTimestamp(ms, datetime) {
			const _fmt = 'YYYY-MM-DD'
			return this.$moment(new Date(ms)).format(_fmt)
		},
		handleSizeChange() {
			this.getDataList();
		},
		handleCurrentChange() {
			this.getDataList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDataList();
		},
		handleAdd() {
			this.id = ''
			this.dialogTitle = '新增延保记录'
			this.readonly = false
			this.visible = true
			this.$nextTick(() => {
				// 将dataForm内的数据重置
				this.dataForm = {
					machineNo: '', // 机号
					extendedDate: '' // 延保结束日期
				}
				this.$refs.dataForm.resetFields();
			})
		},
		handleView(data) {
			this.dialogTitle = '查看延保记录'
			this.readonly = true
			this.dataForm = {
				machineNo: data.machineNo, // 机号
				extendedDate: this.formatTimestamp(data.extendedDate) // 延保结束日期
			}
			this.visible = true
		},
		handleEdit(data) {
			this.dialogTitle = '编辑延保记录'
			this.readonly = false
			this.id = data.id
			this.dataForm = {
				machineNo: data.machineNo, // 机号
				extendedDate: this.formatTimestamp(data.extendedDate) // 延保结束日期
			}
			this.visible = true
		},
		handleDelete(data) {
			this.$confirm('确定删除当前延保记录吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					"repairExtendedRequestDel",
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.$message.success('删除成功')
							that.getDataList()
						}
					}
				)
			}).catch(() => {
			})
		},
		// 重置查询条件
		handleReset() {
			this.pageNo = 1
			this.searchKey = ''
			this.getDataList()
		},
		getDataList() {
			let that = this
			that.$request.post(
				"repairExtendedRequestPageList",
				true,
				{
					searchKey: that.searchKey,
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list
						that.total = r.data.totalCount
					} else {
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		},
		// from 表单提交
		formSubmit: debounce(function () {
			this.submitLoading = true
			this.$refs['dataForm'].validate((valid) => {
				this.dataForm.machineNo = this.dataForm.machineNo.trim()

				if (valid) {
					if (this.id) {
						// 修改
						const data = Object.assign(this.dataForm, {
							id: this.id
						})

						let that = this
						that.$request.post(
							"repairExtendedRequestUpdate",
							false,
							data,
							function (r) {
								if (r.code == "0") {
									that.$message.success('保存成功')
									that.visible = false
									that.getDataList()
								} else {
									that.$message.error(r.msg)
								}
								that.submitLoading = false
							}
						)
					} else {
						// 新增
						let that = this
						that.$request.post(
							"repairExtendedRequestAdd",
							false,
							that.dataForm,
							function (r) {
								if (r.code == "0") {
									that.$message.success('保存成功')
									that.visible = false
									that.getDataList()
								} else {
									that.$message.error(r.msg)
								}
								that.submitLoading = false
							}
						)
					}
				} else {
					this.submitLoading = false
					return false
				}
			})
		}, 1000)
	}
}
</script>

<style lang="scss"
	   scoped>
.block {
	display: block;
}

.data-content {
	.top-box {
		// height: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.add-btn {
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.import-btn {
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.template-btn {
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.top-right-box {
		.label {
			font-size: 14px;
			color: #606266;
			margin-right: 5px;
		}

		.search-input {
			width: 138px;
			margin: 0 10px;
		}

		.search-btn {
			width: 32px;
			vertical-align: middle;
			cursor: pointer;
		}

		.refresh-btn {
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}

	.btn-blue {
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}

	.btn-red {
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}

.dialog-title {
	display: block;
	text-align: center;

	span {
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}

	img {
		width: 28px;
		float: right;
		cursor: pointer;
	}
}

.error-list {
	height: 50vh;
	box-sizing: border-box;
	padding: 20px;
	overflow: auto;

	li {
		list-style: none;
	}
}

.submit-btn {
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}

.role-dialog {
	.inline-form-item {
		display: flex;
		justify-content: space-between;

		.el-form-item {
			width: 100%;

			&:first-of-type {
				padding-right: 10px;
			}

			&:last-of-type {
				padding-left: 10px;
			}

			.el-select, .el-checkbox {
				width: 100%;
			}
		}

	}

	/deep/ .el-dialog {
		border-radius: 10px !important;
	}

	/deep/ .el-dialog__body {
		padding: 0 20px !important;
	}

	/deep/ .el-form-item__label {
		padding: 0 !important;
	}

	/deep/ .el-dialog__footer {
		padding: 20px 20px 30px !important;
	}
}

</style>

<style>
.el-table__fixed-right::before {
	background-color: transparent !important;
}

.scroll-table {
	border: 1px solid #dcdfe6;
}

.scroll-table .yellow {
	background-color: rgba(255, 255, 255);
}

.scroll-table .blue {
	background-color: rgba(246, 249, 253);
}

</style>
